import React from "react";
import "../../styles/components/problemaContato.css";

const MissaoContato = ({theme, modalHandleShow}) => {
  return (
    <section id="missao">
      <div className="container">
        <div>
          <div className="card">
            
          <div className="cardTextoPrincipal">
            <h2 className="tituloProblema">Nossa Missão</h2>
            Um dos principais problemas encontrados no Mercado Avícola é a gestão e manejo do lote das aves. Se não for bem executado, pode gerar muitos prejuízos e impactar negativamente na qualidade final do produto, onde, no pior dos casos, o lote inteiro é descartado.
            <br/>
            Para que esse processo seja feito da melhor forma possível, o Extensionista deve saber quase em tempo real como o Produtor está conduzindo cada fase, e com a informação, poder tomar as decisões necessárias. 
            <br/>
            Para resolver esse problema, a SysAgro apresenta uma solução multiplataforma e de fácil uso para avicultores e extensionistas!
          </div>

          <br/>      
          <div className="infoFinaisCard">
            <span className="tituloInfoFinal">Acabe com o Problema da gestão de aviários</span>
            <small className="descInfoFinal">Otimize todo o processo através da solução SysAgro Aviário.</small>
            <a className="primaryBtn botaoInfoFinal" onClick={() => {modalHandleShow()}} href="#botaoContato">Entre em contato agora!</a>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissaoContato;
