import React from "react";
import "../../styles/screens/hero.css";
import heroDarkImg from "../../images/telaInicialCelular.png";
import lightImg from "../../images/telaInicialCelular.png";

//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Wave from "./Wave";

const Hero = ({theme, modalHandleShow, corWave}) => {
  return (
    <section id="inicio" className="heroSection">
      <Container className="container">
        <Row className="heroWrapper">
          <Col className="heroContent" xs={12} md={6}>
            <div>
              <h2 className="textAlignCenterMD">
                Uma Solução Inovadora para a{" "}
                <span className="highlight">Gestão Avícola</span>
              </h2>
            </div>
            <p className="description textAlignCenterMD">
              Através de um aplicativo mobile integrado com um sistema WEB, a
              SysAgro conecta o produtor e o extensionista praticamente em tempo
              real.
            </p>
            <div className="heroBtns justifyCenterMD">
              <a className="primaryBtn" onClick={() => {modalHandleShow()}} href="#contatoBotao">
                Entre em contato para saber mais!
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <Wave corWave={"var(--body-bg)"} id="caracteristicas"/>
      
    </section>
  );
};

export default Hero;
