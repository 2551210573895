import React from "react";
import "../../styles/components/footer.css";

/* Imagens */
import AmazonWebService from "../../images/parceiros/amazon-web-service.png";
import FCJVenture from "../../images/parceiros/fcj-venturebuilder.png";
import EspacoImpulso from "../../images/parceiros/espaco-impulso.png";
import Fundetec from "../../images/parceiros/fundetec.jpg";
import Logo from "../../images/logoSysAgro.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const getCurrentYear = () => {
	return new Date().getFullYear();
};

const Footer = (theme) => {
	return (
		<section id="footer" className="container">
			<div className="row">
				<div className="itemFooter logoWrapperFooter col-sm-12 col-lg-4">
					<img src={Logo} alt="Logo Sysagro" className="logoSysAgroFooter" />
				</div>
				<div className="itemFooter redesSociaisWrapper col-sm-12 col-lg-4">
					<span className="tituloCardFooter">Nossas Redes: </span>
					<div className="redesSociaisLista">
						<a href="mailto:contato@sysagrosolucoes.com" className="redeSocialItem">
							<FontAwesomeIcon icon={faEnvelope} />
							<div className="redeSocialInfoWrapper">
								<div className="redeSocialItemTitulo">E-mail:</div>
								<div className="redeSocialItemValor">contato@sysagrosolucoes.com</div>
							</div>
						</a>


						<a href="https://www.instagram.com/sysagrosolucoes/" className="redeSocialItem">
							<FontAwesomeIcon icon={faInstagram} />
							<div className="redeSocialInfoWrapper">
								<div className="redeSocialItemTitulo">Instagram:</div>
								<div className="redeSocialItemValor">@sysagrosolucoes</div>
							</div>
						</a>

						<a href="https://www.linkedin.com/company/sysagrosolucoes/" className="redeSocialItem">
							<FontAwesomeIcon icon={faLinkedin} />
							<div className="redeSocialInfoWrapper">
								<div className="redeSocialItemTitulo">LinkedIn:</div>
								<div className="redeSocialItemValor">sysagrosolucoes</div>
							</div>
						</a>
					</div>
				</div>
				<div className="itemFooter parcerias col-sm-12 col-lg-4">
					<span className="tituloCardFooter">Parceiros: </span>
					<div className="row">
						<div className="col-12 col-sm-6 col-lg-6 d-flex justify-content-center align-items-center imagemWrapperFooter">
							<img src={FCJVenture} alt="FCJ Venture BUilder" />
						</div>
						<div className="col-12 col-sm-6 col-lg-6 d-flex justify-content-center align-items-center imagemWrapperFooter">
							<img src={EspacoImpulso} alt="Espaço Impulso" />
						</div>
						<div className="col-12 col-sm-6 col-lg-6 d-flex justify-content-center align-items-center imagemWrapperFooter fundetecImg">
							<img src={Fundetec} alt="Fundetec" />
						</div>
						<div className="col-12 col-sm-6 col-lg-6 d-flex justify-content-center align-items-center imagemWrapperFooter amazonImg">
							<img src={AmazonWebService} alt="Amazon Web Service" />
						</div>
					</div>
				</div>
			</div>

			<div className="trademarkWrapper">
				<div className="trademark">© {getCurrentYear()} SysAgro
				</div>
			</div>
		</section>
	);
};

export default Footer;
