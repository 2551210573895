import React from "react";
import "../../styles/components/diagrama.css";
import imagemDiagrama from "../../images/diagramaSysAgro.png";

const Diagrama = (theme) => {

    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();

    React.useEffect(() => {
        const observerDiagrama = new IntersectionObserver(entries => {
          entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observerDiagrama.observe(domRef.current);
        return () => observerDiagrama.unobserve(domRef.current);
      }, []);

    return (
        <section id="diagramaSection">
            <div className={["container", `${isVisible ? 'visivelDiagrama' : ''}`].join(" ")}>

                <div className="textoDiagrama">
                    O Sistema da Sysagro permite uma conexão eficaz entre o avicultor, extensionista e cooperativa.
                </div>
                <img
                    ref={domRef}
                    src={imagemDiagrama}
                    alt="Diagrama SysAgro"
                    className="imgDiagrama"
                />
            </div>
        </section>
    );
};

export default Diagrama;
